footer{
    width: 100%;
    
    bottom: 0;
    left: 0;
    background: #555555;
  }
  footer .content{
    max-width: 1350px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  footer .content p,a{
    color: #fff;
  }
  footer .content .box{
    width: 33%;
    transition: all 0.4s ease;
  }
  footer .content .topic{
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 16px;
  
  }
  footer .content p{
    text-align: justify;
  }
  footer .content .lower .topic{
    margin: 24px 0 5px 0;
  }
  footer .content .lower i{
    padding-right: 16px;
  }
  footer .content .middle{
    padding-left: 80px;
  }
  footer .content .middle a{
    line-height: 32px;
  }
  footer .content .right input[type="text"]{
    height: 45px;
    width: 100%;
    outline: none;
    color: #d9d9d9;
    background: #555555;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 17px;
    border: 2px solid #222222;
  }
  footer .content .right input[type="submit"]{
    height: 42px;
    width: 100%;
    font-size: 18px;
    color: #d9d9d9;
    background: #008080;
    outline: none;
    border-radius: 5px;
    letter-spacing: 1px;
    cursor: pointer;
    margin-top: 12px;
    border: 2px solid #008080;
    transition: all 0.3s ease-in-out;
  }
  .content .right input[type="submit"]:hover{
    background: none;
    color:  #008080;
  }
  footer .content .media-icons a{
    font-size: 16px;
    height: 45px;
    width: 45px;
    display: inline-block;
    text-align: center;
    line-height: 43px;
    border-radius: 5px;
    border: 2px solid #222222;
    margin: 30px 5px 0 0;
    transition: all 0.3s ease;
  }
  .content .media-icons a:hover{
    border-color: #008080;
  }
  footer .bottom{
    width: 100%;
    text-align: right;
    color: #d9d9d9;
    padding: 0 40px 5px 0;
  }
  footer .bottom a{
    color: #008080;
  }
  footer a{
    transition: all 0.3s ease;
  }
  footer a:hover{
    color: #008080;
  }
  @media (max-width:1100px) {
    footer .content .middle{
      padding-left: 50px;
    }
  }
  @media (max-width:950px){
    footer .content .box{
      width: 50%;
    }
    .content .right{
      margin-top: 40px;
    }
  }
  @media (max-width:560px){
    footer{
      position: relative;
    }
    footer .content .box{
      width: 100%;
      margin-top: 30px;
    }
    footer .content .middle{
      padding-left: 0;
    }
  }
  