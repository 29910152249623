.cart{
    position: relative;
    border: 1px solid #ccc;
    transform: scaleY(0.98);
}
.amount span{
    color: crimson;
    padding: 0 20px;
}
.amount button{
    width: 40px;
    height: 40px;
    border: 1px solid #777;
}

.color {
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    outline: none;
    margin-left: 10px;
    cursor: pointer;
    display: inline-block;

}
form{
    width: 100%;
    text-align: center;
}
.input_buy{
    width: 20%;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    background-color:teal;;
    cursor: pointer;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 6px;
}
.delete{
    position: absolute;
    top:0;
    right: 5px;
    color: crimson;
    font-weight: 900;
    cursor: pointer;
}
.total{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.total h3{
    color: crimson;
}

@media (max-width: 720px){
    .input_buy{
        width: 100%;
    }
}