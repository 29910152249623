.detail{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 50px;
    font-size: 150%;
}

.img-detail {
    width: 40%;
    border: 1px solid #9A9A9A;
}
.detail img{
    max-width: 300px;
    width: 100%;
    margin: 20px;
    height: 300px;
    object-fit: cover;
}

.detail img:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5); 
  }
.box-detail{
    width: 55%;
    margin: 5px 20px;
}

.div-new{
    color: red;
    position: absolute;
    top: 8px;
    left: 16px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc,
                0 3px 0 #ccc, 0 4px 0 #ccc,
                0 5px 0 #ccc, 0 6px 0 #ccc,
                0 7px 0 #ccc, 0 8px 0 #ccc,
                0 9px 0 #ccc, 0 10px 0 #ccc,
                0 11px 0 #ccc, 0 12px 0 #ccc,
                0 20px 30px rgba(0, 0, 0, 0.5);
}
.box-detail .row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-detail h2{
    text-transform: uppercase;
    color: darkblue;
    letter-spacing: 2px;
    font-weight: 2rem;
}
.box-detail p{
    line-height: 1.5;
    margin: 10px 0;
    opacity: 0.8;
}
.box-detail .cart{
    background: #333;
    color: white;
    margin-top: 10px;
    padding: 10px 25px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 2px;
}

/*colors */

.detail .box-detail button{
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
}

.detail .box-detail > p .sizes{
    display: inline-block; 
    margin-left: 10px; 
}

.detail .box-detail > p .sizes > select{
    width: 70px;
    border: 2px solid black;
    font-size: 17px;
    font-weight: bold;
}

.detail .box-detail > p .sizes > select > option{
    font-size: 17px;
    font-weight: bold;
}

@media (max-width: 1600px){ 
    .detail img{
        max-width: 300px;
        width: 100%;
        margin: 10px;
        height: 300px;
        object-fit: cover;
    }

    .box-detail{
        width: 45%;
        margin: 5px 20px;
    }

    .img-detail {
        width: 50%;
        border: 1px solid #9A9A9A;
    }
}

@media (max-width: 1400px){ 
    .detail img{
        max-width: 250px;
    }
}

@media (max-width: 1229px){ 
    .detail img{
        max-width: 200px;
    }
}

@media (max-width: 1059px){ 
    .detail img{
        max-width: 300px;
    }

    .img-detail {
        width: 80%;
        border: 1px solid #9A9A9A;
    }
}

@media (max-width: 955px){ 
    .detail img{
        max-width: 300px;
    }

    .img-detail {
        width: 100%;
        border: 1px solid none;
    }
}

@media (max-width: 789px){ 
    .detail img{
        max-width: 200px;
    }

    .img-detail {
        width: 100%;
        border: 1px solid ;
    }
}

@media (max-width: 500px){ 
    .detail img{
        max-width: 300px;
    }

    .img-detail {
        width: 100%;
        border: 1px solid ;
    }
}