header{
    min-height: 70px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #ddd;
}
header .logo{
    flex: 1;
}

header .logo img{ 
    width: 60px;
}

header a{
    text-transform: uppercase;
    color: #555;
}
header ul li{
    display: inline-block;
    opacity: 0.7;
    padding: 0 20px;
}
header ul li:hover{
    opacity: 1;
}
.menu{
    display: none;
}
.cart-icon{
    position: relative;
    margin-right: 20px;
}
.cart-icon span{
    background: crimson;
    border-radius: 20px;
    color: white;
    position: absolute;
    top:-10px;
    right: -10px;
    padding: 5px 7px;
    font-size: 10px;
}