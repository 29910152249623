.banners{
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    justify-items: center;
    margin: 20px 0;
}

/* ----------- Banner Item ----------------- */
@import url("../utils/BannerItem/bannerItem.css");

.delete-all{
    text-align: right;
    margin: 20px;
}
.delete-all input{
    height: 25px;
    width: 25px;
    transform: translateY(5px);
    margin: 0 15px;
}
.delete-all span{
    text-transform: uppercase;
    color: rgb(3, 165, 206);
    letter-spacing: 1.3px;
}
.delete-all button{
    border: 1px solid crimson;
    padding: 10px 25px;
    color: crimson;
    text-transform: uppercase;
}


